import { Typography } from "@packages/ds";
import styled from "styled-components";

import { ButtonWrapper as BaseButtonWrapper, Link } from "ds/Button";
import LabelDs from "ds/Label";
import * as Sticker from "ds/Sticker";

export const SubHeading = styled(Typography.BodyText)``;

export const VariablesTitle = styled(Typography.BodyText)`
  margin: 20px 0;
`;

export const OperationDescription = styled(Typography.BodyText)``;

export const OperationName = styled.span`
  font-weight: 700;
  color: var(--mode-text-neutral-default);
`;

export const EnvironmentName = styled.b`
  margin-left: 4px;
  color: var(--mode-text-neutral-default);
`;
export const AppDivider = styled.hr`
  margin: 0;
  background-color: var(--mode-border-neutral-weakest);
`;
export const ApplicationList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  li:last-child {
    ${AppDivider} {
      display: none;
    }
  }
`;

export const ApplicationCommandWrapper = styled.div`
  display: flex;
  padding: 4px 0 5px 0;
  justify-content: space-between;
  align-items: center;
`;

export const CommandControl = styled.div`
  gap: 16px;
  display: flex;
  align-items: center;
`;

export const SectionDivider = styled.hr`
  margin-left: -32px;
  margin-right: -32px;
  margin-bottom: 0;
  margin-top: 0;
  background-color: var(--mode-border-neutral-weakest);
`;

export const ApplicationHeaderDivider = styled.hr`
  margin-bottom: 0;
  background-color: var(--mode-border-neutral-weakest);
`;

export const ButtonWrapper = styled(BaseButtonWrapper)`
  bottom: 0;
  position: sticky;
  margin-left: -32px;
  margin-right: -32px;
  padding: 32px 32px 0px 32px;
`;

export const ToggleCommandViewButton = styled.span`
  color: var(--mode-text-primary-default);
  cursor: pointer;
  font-weight: 600;
  line-height: 16px;
  font-size: 14px;
  font-smooth: antialiazed;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const OperationDropdownContainer = styled.div`
  margin-bottom: 24px;
  margin-top: 22px;
`;

export const Applications = styled(SubHeading)`
  margin: 0;
  margin-top: 24px;
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 34px;
  padding-bottom: 34px;
`;

export const Pre = styled.pre`
  margin-top: 0;
`;
export const SelectWrapper = styled.div`
  width: 264px;
`;
export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`;
export const Label = styled(LabelDs)`
  margin-bottom: 0;
`;

export const RuntimeOptionsDescription = styled(Typography.BodyText)`
  padding-top: 20px;
  padding-bottom: 23px;
`;

export const StickerText = styled(Sticker.Text)`
  display: inline;
`;

export const ModalProButtonWrapper = styled(ButtonWrapper)`
  justify-content: space-between;
  padding: 0px 32px;
  margin-top: 30px;
`;
export const ModalButtonWrapper = styled(ButtonWrapper)`
  justify-content: end;
  padding: 0px 32px;
  margin-top: 30px;
`;

export const DocumentationInfo = styled(Typography.BodyText)`
  margin-top: 10px !important;
`;

export const RedirectLink = styled(Link)`
  padding-left: 0px !important;
  font-size: 13px;
  padding-right: 3px;
  height: 16px;
`;

export const StickerRoot = styled(Sticker.Root)`
  padding: 10px 11px;
`;

export const DocumentationLink = styled(Link)`
  margin: 0px !important;
  padding: 0px !important;
`;

export const DocLink = styled(Link)`
  margin-left: 3px;
`;

export const SourceButtonWrapper = styled(BaseButtonWrapper)`
  justify-content: space-between;
  margin-top: 30px;
`;
