import React, { memo } from "react";

import { Tooltip } from "../Tooltip";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import * as S from "./TextOverflow.styles";
import { useHasOverflow } from "./useHasOverflow";

export interface TextOverflowProps<T extends React.ElementType> {
  children: React.ReactNode;
  /** Toltip to be displayed if the content overflows the container */
  tooltip?: React.ReactNode;
  /** Allows to style the layout component */
  className?: string;
  /** Optional polymorphic component */
  as?: T;
  tooltipProps?:   Pick<
      TooltipPrimitive.TooltipContentProps,
      "side" | "align" | "sideOffset" | "alignOffset"
    > & { pointerPosition?: `fromLeft=${string}` | `fromRight=${string}`}
}

/**
 * Sets up a container with text overflow ellipsis, if the text content
 * overflows this container, it will display the content passed as the tooltip
 * content.
 * This conditon is re-tested on each resize.
 */
const TextOverflow = <T extends React.ElementType = "div">({
  children,
  tooltip,
  as = "div",
  tooltipProps,
  ...props
}: TextOverflowProps<T> & React.ComponentProps<T>) => {
  const [displayTooltip, ref] = useHasOverflow();

  if (displayTooltip)
    return (
      <Tooltip tooltip={tooltip || children} {...tooltipProps}>
        <S.Layout as={as} ref={ref} {...props}>
          {children}
        </S.Layout>
      </Tooltip>
    );

  return (
    <S.Layout as={as} ref={ref} {...props}>
      {children}
    </S.Layout>
  );
};

export default memo(TextOverflow);
