import styled, { css } from "styled-components";

import { itemStyles } from "ds/Dropdown/Dropdown.styles";
import { Link as LinkItem } from "ds/Dropdown/LinkItem.styles";
import { semiBoldAlias } from "Libs/theme";

export const Wrapper = styled.div<{ $addPadding?: boolean }>`
  position: relative;
  ${({ $addPadding }) =>
    $addPadding &&
    css`
      padding-left: 16px;
    `}
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`;

export const EnvLinkItem = styled(LinkItem)<{
  $isRoot: boolean;
  $isSelected?: boolean;
}>`
  ${itemStyles}
  padding: 4px 8px;
  overflow: hidden;

  ${({ $isRoot }) => $isRoot && semiBoldAlias()}
`;
