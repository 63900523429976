import styled from "styled-components";

import SecondaryLinkDs from "./SecondaryLink";
import { Icon } from "./SecondaryLink.styles";

export const SecondaryLink = styled(SecondaryLinkDs)`
  & > ${Icon} {
    svg {
      fill: currentColor;
    }
  }
`;
