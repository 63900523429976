import React, { useRef, useState } from "react";

import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import * as Dropdown from "ds/Dropdown";
import { useClickOutside } from "Hooks/useClickOutside";
import useUrls from "Hooks/useUrls";
import Menu from "src/common/components/EnvironmentTree/Menu";

import SimpleMenu from "./SimpleMenu";
import { NavBarLink, DropdownContentEnvironments } from "./styles";

import type { Project, Environment } from "@packages/client";

interface Props {
  project: Project;
  environment?: Environment;
  environmentId?: string;
  organizationId: string;
}

const NavigationMenu = ({
  environmentId,
  project,
  environment,
  organizationId
}: Props) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isProjectOpen, setProjectMenuState] = useState(false);
  const [isEnvOpen, setEnvMenuState] = useState(false);
  const projectMenuRef = useRef(null);

  const [projectOverviewUrl] = useUrls(["organization.project"], {
    organizationId,
    projectId: project?.id ?? ""
  });

  useClickOutside(projectMenuRef, () => setProjectMenuState(false));

  const toggleProjectMenu = () => {
    setProjectMenuState(!isProjectOpen);
  };

  const focusById = (id: string) => {
    setTimeout(() => {
      const focusElement = document.getElementById(id);
      focusElement?.focus();
    }, 100);
  };
  const onArrowClick = () => {
    toggleProjectMenu();
    focusById("navbar-projects");
  };

  const onProjectViewNavigation = () => {
    navigate(projectOverviewUrl);
  };

  const closeEnvDropdown = () => setEnvMenuState(false);

  return (
    <div className="navigation-menu">
      <NavBarLink
        aria-label="Primary menu"
        className={!environmentId ? "active" : ""}
        ref={projectMenuRef}
      >
        <Dropdown.Root open={isProjectOpen}>
          <Dropdown.Trigger variant="large" onClick={() => onArrowClick()}>
            <Dropdown.TriggerContent
              border
              onClick={() => onProjectViewNavigation()}
            >
              <Dropdown.TriggerLabel>
                {intl.formatMessage({ id: "project" }).toUpperCase()}
              </Dropdown.TriggerLabel>
              <Dropdown.TriggerName>{project?.title}</Dropdown.TriggerName>
            </Dropdown.TriggerContent>

            <Dropdown.Chevron
              direction="down"
              fill="var(--mode-vector-neutral-default)"
            />
          </Dropdown.Trigger>
        </Dropdown.Root>

        <SimpleMenu
          aria-label="Projects"
          isOpen={isProjectOpen}
          toggleMenu={toggleProjectMenu}
        />
      </NavBarLink>

      <Dropdown.Root open={isEnvOpen} onOpenChange={setEnvMenuState}>
        <Dropdown.Trigger variant="large" isActive={!!environmentId}>
          <Dropdown.TriggerContent border>
            <Dropdown.TriggerLabel>
              {intl
                .formatMessage({ id: "select_environment.pre_label" })
                .toUpperCase()}
            </Dropdown.TriggerLabel>
            <Dropdown.TriggerName>
              {environment?.title ??
                intl.formatMessage({ id: "select_environment" })}
            </Dropdown.TriggerName>
          </Dropdown.TriggerContent>
          <Dropdown.Chevron
            direction="down"
            fill="var(--mode-vector-neutral-default)"
          />
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <DropdownContentEnvironments>
            <Menu closeEnvDropdown={closeEnvDropdown} />
          </DropdownContentEnvironments>
        </Dropdown.Portal>
      </Dropdown.Root>
    </div>
  );
};

export default NavigationMenu;
