import React from "react";

import { AddIcon, InfoSmIcon as InfoSm } from "@packages/ds";
import { useIntl } from "react-intl";

import { Button, Link, IconControl } from "ds/Button";
import Dialog from "ds/Dialog";
import Input from "ds/Input";

import { VariablesTitle } from "../SourceOperation.style";

import * as S from "./Variables.styles";

const VARIABLE_FIELD = {
  NAME: "name",
  VALUE: "value"
};

type OperationVariableType = {
  name: string;
  value: string;
};

type OperationVariablesProps = {
  variables: OperationVariableType[];
  setVariables: (value) => void;
};

const OperationVariables = ({
  variables,
  setVariables
}: OperationVariablesProps) => {
  const formatMessage = useIntl().formatMessage;

  const onVariableChange = (index, field, input) => {
    const { name, value } = variables[index];
    if (field === VARIABLE_FIELD.NAME) {
      variables[index] = { name: input, value };
      setVariables([...variables]);
      return;
    }
    variables[index] = { name, value: input };
    setVariables([...variables]);
  };

  const onRemoveVariable = index => {
    variables.splice(index, 1);
    setVariables([...variables]);
  };

  return (
    <S.VariableWrapper>
      <S.HeaderSection>
        <S.AddVariableHeader>
          <VariablesTitle weight="semiBold">
            {formatMessage({
              id: "override_variables"
            })}
          </VariablesTitle>

          <Dialog
            align="center"
            description={formatMessage({
              id: "source_ops.define_variables_info"
            })}
            analyticId={"source_ops.define_variables_info"}
            link={
              <Link
                analyticId="learnmore"
                href={formatMessage({
                  id: "links.documentation.variables.run_and_build_time"
                })}
              >
                {formatMessage({ id: "learnmore" })}
              </Link>
            }
            trigger={
              <IconControl>
                <InfoSm />
              </IconControl>
            }
          />
        </S.AddVariableHeader>

        <Button
          content="mixed"
          variant="link"
          analyticId="settings.variables.add.title"
          className="add-variable-wrapper"
          onClick={event => {
            event.preventDefault();
            setVariables([{ name: "", value: "" }, ...variables]);
          }}
        >
          <AddIcon />
          {formatMessage({ id: "settings.variables.add.title" })}
        </Button>
      </S.HeaderSection>
      <S.VariableInfo>{formatMessage({ id: "variables_info" })}</S.VariableInfo>
      {variables.map((input, i) => (
        <S.VariableInput
          className={`operation-variable operation-variable-${i}`}
          data-testid={`operation-variable-${i}`}
          key={`${input}_${i}`}
        >
          <Input
            id={`variable-name-${i}`}
            label={formatMessage({ id: "source_ops.variable.name" })}
            placeholder={formatMessage({
              id: "source_ops.variable.name.placeholder"
            })}
            analyticId="source_ops.variable.name"
            value={input.name}
            required={!!input.value}
            onChange={({ target }) =>
              onVariableChange(i, VARIABLE_FIELD.NAME, target.value)
            }
            width="100%"
          />
          <Input
            required={!!input.name}
            id={`variable-value-${i}`}
            label={formatMessage({ id: "source_ops.variable.value" })}
            placeholder={formatMessage({
              id: "source_ops.variable.value.placeholder"
            })}
            analyticId="source_ops.variable.value"
            value={input.value}
            onChange={({ target }) =>
              onVariableChange(i, VARIABLE_FIELD.VALUE, target.value)
            }
            width="100%"
          />
          <S.CloseButton onClick={() => onRemoveVariable(i)} />
        </S.VariableInput>
      ))}
    </S.VariableWrapper>
  );
};

export default OperationVariables;
