import React, { useMemo, useState, useEffect } from "react";

import { InfoSmIcon, LoadingIcon, Typography } from "@packages/ds";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, CopyButton, IconControl } from "ds/Button";
import * as Code from "ds/Code";
import Dialog from "ds/Dialog";
import Label from "ds/Label";
import Select from "ds/Select";
import * as Sticker from "ds/Sticker";

import * as S from "./SourceOperation.style";
import OperationVariables from "./variable/Variables";

import type {
  SourceOperation,
  Environment,
  Deployment,
  DeploymentService
} from "@packages/client";

type RuntimeOptionType = { label: string; value: string };

type RunOperationProps = {
  label: string;
  options: {
    label: string;
    value: DeploymentService["operations"][string];
    type: string;
  }[];
};
type SourceOperationProps = {
  label: SourceOperation["operation"];
  value: SourceOperation;
};

type RunSourceOpsProps = {
  onClose: () => void;
  sourceOperations: SourceOperation[];
  run: (
    sourceOperation: SourceOperation,
    variables: { env: Record<string, string> }
  ) => void;
  environment?: Environment;
  deployment?: Deployment;
  runRuntimeOp: ({
    service,
    operation
  }: {
    service: string;
    operation: string;
  }) => void;
  isRuntime?: boolean;
  errorMessage?: string;
  isLoading: boolean;
};

export const RunSourceOps = ({
  onClose,
  sourceOperations,
  run,
  environment,
  deployment,
  runRuntimeOp,
  isRuntime,
  errorMessage,
  isLoading
}: RunSourceOpsProps) => {
  const [variables, setVariables] = useState<
    {
      name: string;
      value: string;
    }[]
  >([{ name: "", value: "" }]);
  const [expandedCommand, setExpandedCommand] = useState<SourceOperation[]>([]);
  const [selectedSourceOps, setSelectedSourceOps] = useState<
    SourceOperationProps | RunOperationProps["options"][number]
  >();
  const [selectedRuntimeOps, setSelectedRuntimeOps] = useState<
    RunOperationProps["options"][number] | SourceOperationProps
  >();
  const [selectedOps, setSelectedOps] = useState<SourceOperationProps>();
  const [selectedWorkerOrApp, setSelectedWorkerOrApp] =
    useState<RuntimeOptionType>();

  const [isRuntimeCommandExpanded, setIsRuntimeCommandExpanded] =
    useState(false);

  const formatMessage = useIntl().formatMessage;

  const uniqueOperations = useMemo(
    () =>
      sourceOperations?.reduce<SourceOperation[]>((uniqueOps, operation) => {
        if (uniqueOps.some(op => op.operation === operation.operation)) {
          return uniqueOps;
        }
        uniqueOps.push(operation);
        return uniqueOps;
      }, []),
    [sourceOperations]
  );
  /**
   * if all source operations has the same operation name defined for them
   * regardless of the operation name or command, assume it has one operation
   * because when any instance of such operation is run, it will run on all the
   * app having similar operation name
   */
  const sourceOpsHasSimilarOperations = useMemo(
    () =>
      sourceOperations?.every(operation =>
        sourceOperations.every(ops => operation.operation === ops.operation)
      ),
    [sourceOperations]
  );

  const opsWithSameOperationName = useMemo(
    () =>
      sourceOperations?.filter(operation => {
        return operation.operation === selectedSourceOps?.value?.operation;
      }),
    [sourceOperations, selectedSourceOps?.value?.operation]
  );

  const onExpandCommand = (command: SourceOperation) => {
    if (expandedCommand.includes(command)) {
      setExpandedCommand(
        expandedCommand.filter(thisCMD => thisCMD !== command)
      );
    } else {
      setExpandedCommand([...expandedCommand, command]);
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (selectedSourceOps) {
      const vars = variables
        ?.filter(({ name, value }) => name && value)
        .reduce<Record<string, string>>((acc, next) => {
          acc[next.name] = next.value;
          return acc;
        }, {});

      run(selectedSourceOps.value, { env: vars });
    }
    if (selectedRuntimeOps) {
      runRuntimeOp({
        operation: selectedRuntimeOps.label,
        service: selectedWorkerOrApp!.label
      });
    }
  };
  const sourceOps = uniqueOperations?.map(value => ({
    label: value.operation,
    value,
    type: "source"
  }));

  const runtimeOperations = useMemo(() => {
    const webAppRuntimeOps = Object.values(deployment?.webapps || {}).flatMap(
      app =>
        Object.entries(app.operations || {}).map<
          RunOperationProps["options"][number]
        >(([operationName, operation]) => ({
          label: operationName,
          value: operation,
          type: "runtime"
        }))
    );

    const workerRuntimeOps = Object.values(deployment?.workers || {}).flatMap(
      worker =>
        Object.entries(worker.operations || {}).map<
          RunOperationProps["options"][number]
        >(([operationName, operation]) => ({
          label: operationName,
          value: operation,
          type: "runtime"
        }))
    );

    return [...webAppRuntimeOps, ...workerRuntimeOps].filter(
      operation => Object.keys(operation.value).length
    );
  }, [deployment]);

  const operations = isRuntime
    ? [{ label: "Runtime operations", options: runtimeOperations }]
    : [{ label: "Source operations", options: sourceOps }];

  useEffect(() => {
    if (
      sourceOpsHasSimilarOperations &&
      sourceOperations.length &&
      !isRuntime
    ) {
      setSelectedSourceOps({
        label: sourceOperations[0].operation,
        value: sourceOperations[0]
      });
      setSelectedOps({
        label: sourceOperations[0].operation,
        value: sourceOperations[0]
      });
    }
    if (
      sourceOpsHasSimilarOperations &&
      runtimeOperations.length &&
      isRuntime
    ) {
      setSelectedRuntimeOps({
        label: runtimeOperations[0].label,
        value: runtimeOperations[0].value
      });
      setSelectedOps({
        label: runtimeOperations[0].label,
        value: runtimeOperations[0].value
      });
    }
  }, [
    sourceOperations,
    isRuntime,
    runtimeOperations,
    sourceOpsHasSimilarOperations
  ]);

  const onOperationSelect = (
    operation: SourceOperationProps | RunOperationProps["options"][number]
  ) => {
    setSelectedOps(operation);
    if (
      (operation as RunOperationProps["options"][number])?.type === "source"
    ) {
      setSelectedSourceOps(operation);
      setSelectedRuntimeOps(undefined);
      setSelectedWorkerOrApp(undefined);
    } else {
      setSelectedRuntimeOps(operation);
      setSelectedSourceOps(undefined);
    }
  };
  const onWorkerOrAppSelect = (workerOrApp: RuntimeOptionType) => {
    setSelectedWorkerOrApp(workerOrApp);
  };

  const runtimeOptions: RuntimeOptionType[] = [];
  deployment?.workers &&
    Object.keys(deployment.workers).forEach(key => {
      runtimeOptions.push({
        label: key,
        value: deployment.workers[key]?.name
      });
    });
  deployment?.webapps &&
    Object.keys(deployment.webapps).forEach(key => {
      runtimeOptions.push({
        label: key,
        value: deployment.webapps[key]?.name
      });
    });
  const onCloseModal = () => {
    onClose();
  };

  const shouldShowDropdown = operations[0].options.length > 1;

  const selectRuntimeSourceText = (
    isRuntime,
    selectedSourceOps,
    selectedRuntimeOps
  ) => {
    if (isRuntime) {
      if (selectedRuntimeOps) {
        return "source_ops.runtime.runnable";
      }
      return "source_ops.runtime.select_operation_many";
    } else {
      if (selectedSourceOps) {
        return "source_ops.runnable";
      }
      return "source_ops.select_operation_many";
    }
  };

  const selectOperation = (
    isRuntime,
    selectedSourceOps,
    selectedRuntimeOps
  ) => {
    if (isRuntime) {
      if (selectedRuntimeOps) {
        return `: ${selectedRuntimeOps?.label}`;
      }
      return "";
    } else {
      if (selectedSourceOps) {
        return `: ${selectedSourceOps?.value.operation}`;
      }
      return "";
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Typography.Title tag="h3">
          <FormattedMessage
            id={isRuntime ? "source_ops.runtime.run" : "source_ops.run"}
            values={{
              operation: selectOperation(
                isRuntime,
                selectedSourceOps,
                selectedRuntimeOps
              )
            }}
          />
        </Typography.Title>
        {errorMessage && (
          <Sticker.Root priority="critical">
            <Sticker.Icon priority="critical" />
            {errorMessage}
          </Sticker.Root>
        )}
        <S.OperationDescription>
          <FormattedMessage
            id={selectRuntimeSourceText(
              isRuntime,
              selectedSourceOps,
              selectedRuntimeOps
            )}
            values={{
              operation: (
                <S.OperationName>
                  {isRuntime
                    ? selectedRuntimeOps?.label
                    : selectedSourceOps?.value?.operation}
                </S.OperationName>
              ),
              environment: (
                <S.EnvironmentName>{environment?.name}</S.EnvironmentName>
              )
            }}
          />{" "}
        </S.OperationDescription>

        {shouldShowDropdown && (
          <S.OperationDropdownContainer data-testid="source-operation-dropdown-container">
            <S.SelectWrapper>
              <S.LabelWrapper>
                <S.Label required>{formatMessage({ id: "operation" })}</S.Label>
                <Dialog
                  align="center"
                  analyticId={
                    isRuntime
                      ? "source_ops.runtime.configure_env.learnmore"
                      : "source_ops.configure_env.learnmore"
                  }
                  description={formatMessage<React.ReactElement>(
                    {
                      id: isRuntime
                        ? "source_ops.runtime.configure_env.learnmore"
                        : "source_ops.configure_env.learnmore",
                      defaultMessage:
                        "Learn how to configure your environment to run Source and/or Runtime Operations in our documentation"
                    },
                    {
                      link: (...chunks) => (
                        <S.DocumentationLink
                          target="_blank"
                          analyticId={
                            isRuntime
                              ? "links.documentation.runtime_operations"
                              : "links.documentation.source_operations"
                          }
                          to={formatMessage({
                            id: isRuntime
                              ? "links.documentation.runtime_operations"
                              : "links.documentation.source_operations"
                          })}
                        >
                          {chunks}
                        </S.DocumentationLink>
                      )
                    }
                  )}
                  trigger={
                    <IconControl>
                      <InfoSmIcon />
                    </IconControl>
                  }
                />
              </S.LabelWrapper>
              <Select
                value={
                  operations[0]?.options?.find(
                    o => o.label === selectedOps?.label
                  ) ||
                  operations[1]?.options?.find(
                    o => o.label === selectedOps?.label
                  ) ||
                  null
                }
                options={operations}
                data-testid="source-operation-dropdown"
                id="source-operation-dropdown"
                isSearchable
                onChange={option =>
                  onOperationSelect(
                    option as
                      | RunOperationProps["options"][number]
                      | SourceOperationProps
                  )
                }
                isClearable={false}
                required={true}
                placeholder={formatMessage({ id: "select" })}
              />
            </S.SelectWrapper>
          </S.OperationDropdownContainer>
        )}
        {sourceOpsHasSimilarOperations && selectedSourceOps && (
          <>
            <S.Applications>
              {formatMessage({ id: "applications" })}
            </S.Applications>
            <S.ApplicationHeaderDivider />
          </>
        )}

        <S.ApplicationList>
          {opsWithSameOperationName?.map((operation, i) => {
            const isExpanded = expandedCommand.includes(operation);
            return (
              <li key={i} data-testid={`source-application-${operation.app}`}>
                <S.ApplicationCommandWrapper>
                  <S.SubHeading weight="semiBold">{operation.app}</S.SubHeading>
                  <S.CommandControl>
                    <S.ToggleCommandViewButton
                      data-testid={`${operation.app}-command-toggle`}
                      role="button"
                      tabIndex={0}
                      onClick={() => onExpandCommand(operation)}
                    >
                      {isExpanded
                        ? formatMessage({ id: "command.hide" })
                        : formatMessage({ id: "command.view" })}
                    </S.ToggleCommandViewButton>
                    <CopyButton
                      text={operation.command}
                      variant="link"
                      withText={false}
                      analyticId="copy"
                    />
                  </S.CommandControl>
                </S.ApplicationCommandWrapper>
                {isExpanded && (
                  <div
                    data-testid={`application-${operation.app}-command-wrapper`}
                  >
                    <S.Pre>
                      <Code.Block
                        lineNumber={0}
                        timestamp=""
                        id="operation.command"
                      >
                        {operation.command}
                      </Code.Block>
                    </S.Pre>
                  </div>
                )}
                <S.AppDivider />
              </li>
            );
          })}
        </S.ApplicationList>
        {selectedSourceOps && (
          <>
            {" "}
            <S.SectionDivider />
            <OperationVariables
              variables={variables}
              setVariables={value => setVariables(value)}
            />
          </>
        )}
        {selectedRuntimeOps && (
          <>
            <S.RuntimeOptionsDescription size="medium">
              {formatMessage({
                id: "source_ops.runtime.worker",
                defaultMessage:
                  "Choose an application or worker to run this operation during runtime."
              })}
            </S.RuntimeOptionsDescription>
            <S.SelectWrapper>
              <Label required>
                {formatMessage({ id: "application_webworker" })}
              </Label>
              <Select
                value={
                  runtimeOptions.find(
                    o => o.value === selectedWorkerOrApp?.value
                  ) || null
                }
                options={runtimeOptions}
                data-testid="source-operation-dropdown"
                id="source-operation-dropdown"
                isSearchable
                onChange={option =>
                  onWorkerOrAppSelect(option as RuntimeOptionType)
                }
                isClearable={false}
                required={true}
                placeholder={formatMessage({ id: "select" })}
              />
            </S.SelectWrapper>
          </>
        )}
        {selectedWorkerOrApp && selectedRuntimeOps && (
          <S.ApplicationList className="source-application-list">
            <hr></hr>
            <li>
              <S.ApplicationCommandWrapper>
                <S.SubHeading weight="semiBold">
                  {selectedWorkerOrApp.label}
                </S.SubHeading>
                <S.CommandControl>
                  <S.ToggleCommandViewButton
                    role="button"
                    tabIndex={0}
                    onClick={() => setIsRuntimeCommandExpanded(prev => !prev)}
                  >
                    {isRuntimeCommandExpanded
                      ? formatMessage({ id: "command.hide" })
                      : formatMessage({ id: "command.view" })}
                  </S.ToggleCommandViewButton>
                  <CopyButton
                    text={selectedRuntimeOps.value.commands.start}
                    variant="link"
                    withText={false}
                    analyticId="copy"
                  />
                </S.CommandControl>
              </S.ApplicationCommandWrapper>
              {isRuntimeCommandExpanded && (
                <S.Pre>
                  <Code.Block
                    lineNumber={0}
                    timestamp=""
                    id="selectedRuntimeOps.value.commands.start"
                  >
                    {selectedRuntimeOps.value.commands.start}
                  </Code.Block>
                </S.Pre>
              )}
              <S.AppDivider />
            </li>
          </S.ApplicationList>
        )}

        <S.ButtonWrapper justifyContent="end">
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={onCloseModal}
                analyticId="cancel"
              >
                {formatMessage({ id: "cancel" })}
              </Button>{" "}
              <Button
                type="submit"
                disabled={!selectedSourceOps && !selectedWorkerOrApp}
                analyticId="run"
              >
                {formatMessage({ id: "run" })}
              </Button>
            </>
          )}
        </S.ButtonWrapper>
      </form>
    </div>
  );
};

export default RunSourceOps;
