import React, { useEffect } from "react";

import { LoadingIcon } from "@packages/ds";
import { useNavigate } from "react-router-dom";

import Modal from "ds/Modal";
import useSelectorWithUrlParams from "Hooks/useSelectorWithUrlParams";
import getUrl from "Libs/getUrl";
import { environmentSelector } from "Reducers/environment";
import { deploymentSelectors } from "Reducers/environment/deployment";
import {
  runRuntimeOperation,
  selectIsLoadingRuntimeOperation,
  selectRuntimeOperationError
} from "Reducers/environment/runtimeOperations";
import {
  loadSourceOperations,
  runOperation,
  selectSourceOperations,
  selectSourceOperationsAreLoading,
  selectSourceOperationsError
} from "Reducers/environment/sourceOperations";
import { organizationProfileSelector } from "Reducers/organization/profile";
import {
  projectCapabilitySelector,
  selectCapabilitiesAreLoading
} from "Reducers/project/capabilities";
import { getCommonError } from "Reducers/sliceFactory";
import { useAppSelector, useAppDispatch } from "Store/hooks";

import ConfigureOperationNotice from "./notice/ConfigureSourceOps";
import UpgradeSourceOps from "./notice/UpgradeSourceOps";
import RunSourceOps from "./RunOperation";
import * as S from "./SourceOperation.style";

import type { Activity, SourceOperation } from "@packages/client";

type SourceOperationsProps = {
  isOpen: boolean;
  onClose: () => void;
  organizationId: string;
  projectId: string;
  environmentId: string;
  isRuntime: boolean;
};
const SourceOperations = ({
  isOpen,
  onClose,
  organizationId,
  projectId,
  environmentId,
  isRuntime
}: SourceOperationsProps) => {
  const dispatch = useAppDispatch();

  const environment = useSelectorWithUrlParams(environmentSelector, {
    organizationId,
    projectId,
    environmentId
  });

  const isLoadingCapabilities = useAppSelector(state =>
    selectCapabilitiesAreLoading(state, { organizationId, projectId })
  );

  const isLoadingOperations = useAppSelector(
    state =>
      selectSourceOperationsAreLoading(state) ||
      selectIsLoadingRuntimeOperation(state)
  );

  const navigate = useNavigate();

  const { sourceOperations, capabilities } = useAppSelector(state => ({
    sourceOperations: selectSourceOperations(state, {
      organizationId,
      projectId,
      environmentId
    }),
    capabilities: projectCapabilitySelector(state, {
      organizationId,
      projectId
    })
  }));

  const { currentDeployment } = deploymentSelectors;
  const deployment = useAppSelector(state =>
    currentDeployment(state, {
      organizationId,
      projectId,
      environmentId
    })
  );
  const webApps = deployment?.webapps || {};
  const webAppNames = Object.keys(webApps);

  const hasRuntimeOps = webAppNames.some(
    appName => Object.keys(webApps[appName].operations).length
  );

  const hasSourceOps = sourceOperations?.length;

  const runSourceOp = (
    sourceOperation: SourceOperation,
    variables: { env: Record<string, string> }
  ) => {
    const onRuntimeOperationCompleted = ({ id }: Activity) => {
      onClose();
      navigate(
        getUrl({
          key: "organization.project.environment.log",
          props: { organizationId, projectId, environmentId, activityId: id }
        })
      );
    };
    dispatch(
      runOperation({ sourceOperation, variables, onRuntimeOperationCompleted })
    );
  };

  const runRuntimeOp = ({
    service,
    operation
  }: {
    service: string;
    operation: string;
  }) => {
    const onRuntimeOperationCompleted = ({ id }: Activity) => {
      onClose();
      navigate(
        getUrl({
          key: "organization.project.environment.log",
          props: { organizationId, projectId, environmentId, activityId: id }
        })
      );
    };

    dispatch(
      runRuntimeOperation({
        service,
        operation,
        environmentId,
        projectId,
        deploymentId: deployment?.id!,
        onRuntimeOperationCompleted
      })
    );
  };

  useEffect(() => {
    if (capabilities?.source_operations?.enabled)
      dispatch(
        loadSourceOperations({
          organizationId,
          projectId,
          environmentId
        })
      );
  }, [dispatch, organizationId, projectId, environmentId, capabilities]);

  const profile = useAppSelector(state =>
    organizationProfileSelector(state, { organizationId })
  );

  const sourceOpsError = getCommonError(
    useAppSelector(selectSourceOperationsError)
  ).error;

  const runtimeOpsError = getCommonError(
    useAppSelector(selectRuntimeOperationError)
  ).error;

  const isPro = profile?.account_tier === "pro";
  return (
    <Modal
      isOpen={isOpen}
      size={hasSourceOps || hasRuntimeOps ? "large" : "small"}
      onOpenChange={onClose}
    >
      {isLoadingCapabilities ? (
        <S.LoadingWrapper>
          <LoadingIcon />
        </S.LoadingWrapper>
      ) : (
        <div>
          {isPro ? (
            <UpgradeSourceOps isRuntime={isRuntime} onClose={onClose} />
          ) : hasSourceOps && !isRuntime ? (
            <RunSourceOps
              isLoading={!!isLoadingOperations}
              errorMessage={sourceOpsError.message}
              run={runSourceOp}
              runRuntimeOp={runRuntimeOp}
              sourceOperations={sourceOperations}
              onClose={onClose}
              environment={environment}
              deployment={deployment}
            />
          ) : hasRuntimeOps && isRuntime ? (
            <RunSourceOps
              isLoading={!!isLoadingOperations}
              errorMessage={runtimeOpsError.message}
              run={runSourceOp}
              runRuntimeOp={runRuntimeOp}
              sourceOperations={sourceOperations}
              onClose={onClose}
              environment={environment}
              deployment={deployment}
              isRuntime={isRuntime}
            />
          ) : (
            <ConfigureOperationNotice
              onClose={onClose}
              isPro={isPro}
              isRuntime={isRuntime}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default SourceOperations;
