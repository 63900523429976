import React from "react";

import { Typography } from "@packages/ds";
import { useTheme } from "styled-components";

import * as S from "./OrganizationMenu.styles";

type OrganizationItemContentProps = {
  label: string;
  vendor?: string;
  showVendor?: boolean;
};

const OrganizationItemContent = ({
  label,
  vendor,
  showVendor
}: OrganizationItemContentProps) => {
  const theme = useTheme();

  return (
    <S.OrganizationListItemContent>
      {showVendor && (
        <S.ListItemIcon
          src={`/images/logo-${vendor}${theme.name === "dark" ? "-dark" : ""}.svg`}
          alt="Vendor Logo"
        />
      )}
      <Typography.TextOverflow
        tooltip={label}
        tooltipProps={{
          align: "start",
          pointerPosition: "fromLeft=0"
        }}
      >
        <S.OrganizationListItemName>{label}</S.OrganizationListItemName>
      </Typography.TextOverflow>
    </S.OrganizationListItemContent>
  );
};

export default OrganizationItemContent;
